import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DxDataGridComponent } from 'devextreme-angular';
import { PerformanceMeasurementService } from './performance-measurement.service';
import { LoggingService } from './logging.service';

export function saveDatagridState(state, key: string) {
  if (state.selectedRowKeys) delete state.selectedRowKeys;
  sessionStorage.setItem(key, JSON.stringify(state));
  for (const item in state) {
    if (item !== 'pageSize' && item !== 'columns') {
      delete state[item];
    } else if (item === 'columns') {
      for (const column of state[item]) {
        delete column['filterValue'];
        delete column['filterValues'];
      }
    }
  }
  localStorage.setItem(key, JSON.stringify(state));
}

export function loadDatagridState(key: string) {
  if (JSON.parse(sessionStorage.getItem(key))) {
    return JSON.parse(sessionStorage.getItem(key));
  } else {
    return JSON.parse(localStorage.getItem(key));
  }
}

@Injectable({
  providedIn: 'root',
})
export class DataGridUtilsService {
  constructor(
    private perf: PerformanceMeasurementService,
    private log: LoggingService
  ) {}

  keepDataGridSizeUpdated(dxDataGrid: DxDataGridComponent): Subscription {
    // Updating the height of the grid immediately might not take into account
    // the fact, that there might be some elements that are just beiing destroyed.
    // Adding a little bit of timeout works e.g. when switching between Report 123 and 121
    setTimeout(() => this.updateGridHeight(dxDataGrid), 20);
    return fromEvent(window, 'resize')
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.updateGridHeight(dxDataGrid);
      });
  }

  getSorting(dxDataGrid: DxDataGridComponent): string | undefined {
    if (dxDataGrid) {
      for (const column of dxDataGrid.instance.getVisibleColumns()) {
        if (column.sortOrder === 'asc') {
          return `${column.dataField}`;
        } else if (column.sortOrder === 'desc') {
          return `-${column.dataField}`;
        }
      }
    }
  }

  getSort(sorting): string | undefined {
    if (sorting) return (sorting[0].desc ? '-' : '') + sorting[0].selector;
    return undefined;
  }

  updateGridHeight(dxDataGrid: DxDataGridComponent) {
    this.perf.start('update-grid-height');
    this.log.debug('updateGridHeight', 'Aktualizace vysky datagridu.');
    const dataGridElement = document.getElementById('data-grid');
    if (dxDataGrid) {
      const offsetTop = dataGridElement.getBoundingClientRect().top;
      const bottomMargin = 5;
      const newHeight = window.innerHeight - offsetTop - bottomMargin;
      const oldHeight = dxDataGrid.instance.option('height');
      if (oldHeight !== newHeight) {
        dxDataGrid.instance.option('height', newHeight);
        requestAnimationFrame(() => dxDataGrid.instance.updateDimensions());
      }
    }
    this.perf.measure('update-grid-height');
  }

  getFilters<T>(filter, sort) {
    const filters = {} as T;
    filters['orderBy'] = this.getSort(sort);
    if (filter && Array.isArray(filter)) {
      if (Array.isArray(filter[0])) {
        const filterArrays = filter.filter((value) => Array.isArray(value));

        filterArrays.forEach((value) => {
          filters[value[0]] = value[2];
        });

        return filters;
      } else {
        return { ...filters, [filter[0]]: filter[2] };
      }
    }
    return filters;
  }
}
